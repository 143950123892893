export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'uk-UA',
    globalInjection: true,
    messages: {
        "uk-UA": {
            heads: {
                teamCreation: "Створення екіпажу",
                divisionCreation: "Створення підрозділу",
                userCreation: "Створення користувача",
                zoneCreation: "Створення зони",
            },
            titles: {
                zoneWillBeAdded: 'Буде додано до підрозділу',
                tableNoData: 'Дані відсутні',
                tableLoading: 'Завантаження даних',
                tableItemsPerPage: 'Елементів на сторінці',
                tableAllItemsPerPage: 'Всі',
                flightTimeoutMinutes: 'Тривалість польоту, в хвилинах',
                allowAdditionalMissionTime: 'Дозволити додатковий політний час',
                allowAdditionalMissionTimeTooltip: 'Дозволити пілоту разово продовжувати місію на 5 хвилин' +
                    ' за 3 хвилини до завершення місії',
                mission: {
                    status: {
                        free: 'Вільний',
                        active: 'В небі',
                        queued: 'В черзі',
                    }
                },
                role: {
                    viewer:	"Переглядач",
                    team:	"Член екіпажу",
                    istar_division:	"Командир",
                    istar_zone:	"Диспетчер зони",
                    admin:	"Адміністратор",
                    otg_coordinator:	"Координатор ОТУ"
                },
                sessions: {
                    rejectSession: 'Завершення бойового чергування',
                },
                allowExtraChannel: 'Дозволити резервний канал',
                allowExtraChannelTooltip: 'Дозволити до застосування екіпажем одночасно двох каналів в черзі',
                teamsFlightTimeoutMinutes: 'Особлива тривалість польоту',
                teamsFlightTimeoutMinutesTooltip: 'Якщо не задано, буде використано значення максимальної тривалості польоту, встановлене на рівні зони. Вкажіть значення в хвилинах для перевизначення цього налаштування для екіпажу.',
            },
            texts: {
                sessions: {
                    rejectSession: 'Ви дійсно бажаєте скасувати бойове чергування?',
                }
            },
            validations: {
                required: "Це поле обов'язкове для заповнення",
                minLength: "Довжина значення не може бути меншою, ніж {min} символів",
                maxLength: "Довжина значення не може перевищувати {max} символів",
                email: "Введений email некоректний",
                pilotNotSelected: 'Для бронювання необхідно вибрати пілота',
                bandNotSelected: 'Для бронювання необхідно вибрати смугу частот',
                channelNotSelected: 'Для бронювання необхідно вибрати канал частот',
                zoneNotSelected: 'Для бронювання необхідно вибрати зону',
                repeaterChannelWrong: 'Для каналу ретранслятора можна використати лише до 4-х латинських літер або цифр',
            },
            api: {
                somethingWentWrong: 'На сервері сталась помилка, спробуйте пізніше',
                clientRequestError: 'Щось пішло не так, спробуйте ще раз або зверніться за технічною підтримкою',
                errors: {
                    email_already_exists: 'Користувач з такою поштою уже є в системі, додання нового неможливе',
                    unauthorized: "Доступ заборонений, необхідно спочатку увійти в систему",
                    cannot_remove_osg_with_active_otg: "Неможливо вилучити ОСУВ так як до нього є прикріплений ОТУВ",
                    cannot_remove_otg_with_active_zones: "Неможливо вилучити ОТУВ так як до нього є прикріплені зони"
                },
                messages: {
                    sessionCannotBeFinished: 'Неможливо завершити бойове чергування, ' + 
                        'усі місії в рамках БЧ мають бути завершені.',
                    cannotStarMissionThereIsActiveMission: 'Неможливо почати нову місію, в черзі уже є активна місія.' +
                        ' Якщо вона не відображається, спробуйте оновити сторінку.'
                }
            },
            units: {
                minutes: 'Хвилини',
            },
            freqsStatus: {
                busy: 'Зайнято',
                free: 'Вільно',
                nearBusy: 'Поруч зайнята частота {cause}',
                IMD: 'IMD',
                nearImd: 'IMD поруч - {cause}',
                overlap: 'Створить завади для - {cause}',
            },
            messages: {
                busyMainChannelMessage: 'Зайнятий канал',
                busySecondChannelMessage: 'Зайнятий резервний канал',
                repeaterChipsMessage: 'Зайнятий канал ретранслятора',
            },
        }
    }
}))